import * as React from 'react';
import classnames from 'classnames';

import { HaldiButton } from '@src/routes/common/components/HaldiButton';

// import { useAnimation } from './useAnimation';
import styles from './styles.module.css';

export interface HomepageWeAreProps {
  className?: string;
}

/**
 * @name HomepageWeAre
 * @description We all have those kinda friends that know way too much.
 * We're just that way about "Skin care".
 */
export const HomepageWeAre = (props: HomepageWeAreProps) => {
  const { className } = props;

  // Hooks
  const refEntryPoint = React.useRef<HTMLDivElement>(null);

  // Styles
  const cssComponent = classnames(styles.component, className);
  const cssCta = classnames('mt-4x text-white', styles.cta);
  const cssReflow = classnames('flex', styles.reflow);

  // Animation
  // useAnimation(refEntryPoint);

  return (
    <div className={cssComponent}>
      <div className="ui-container-xl" ref={refEntryPoint}>
        <h2 className={styles.heading}>
          <span className="text-forest">We’re like that friend</span> who knows
          a little too much about skincare
        </h2>

        <div className={cssReflow}>
          <div className={styles.founders}>
            <div className="flex justify-start">
              <p className={styles.founder}>
                Simran Kalra
                <br />
                founder of Haldi
              </p>
            </div>
            <img
              alt=""
              className={styles.image}
              src="/images/rebrand/founders.png"
            />
            <div className="flex justify-end">
              <p className={styles.founder}>
                Julia Huang
                <br />
                &nbsp;founder of Haldi
              </p>
            </div>
          </div>
          <div>
            <p className={styles.copy}>
              In 2018, Simran built a routine for a friend, who told a friend
              who told a friend. Now we’re a small and passionate team, building
              custom skincare routines, one customer at a time.
            </p>
            <div className="text-center md:text-left">
              <HaldiButton
                bordersX="turmeric"
                bordersY="forest"
                className={cssCta}
                href="/about-us"
                thick={true}
              >
                More about our journey
              </HaldiButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
