import * as React from 'react';
import { color } from '@haldi/style-guide';
import classnames from 'classnames';
import { useRouter } from 'next/router';

import { SITE_SURVEY_CTA, SITE_SURVEY_PATH } from '@src/config/constants';
import { HaldiButton } from '@src/routes/common/components/HaldiButton';
import { HaldiLogo } from '@src/routes/common/components/HaldiLogo';

// import { useAnimation } from './useAnimation';
import styles from './styles.module.css';

export interface HomepageIntroProps {
  className?: string;
}

/**
 * @name HomepageIntro
 * @description A big chunky brand logo and tagline, our Homepage Hero/Intro.
 */
export const HomepageIntro = (props: HomepageIntroProps) => {
  const { className } = props;

  // Hooks
  const refEntryPoint = React.useRef<HTMLDivElement>(null);
  const router = useRouter();

  // Setup
  const { stormy, pine, rust, turmeric } = color;
  const colors = [stormy, pine, turmeric, stormy, rust, pine, turmeric, stormy, turmeric, rust]; // prettier-ignore

  // Styles
  const tailwind = 'ui-container-xl flex flex-col';
  const cssComponent = classnames(styles.component, className);
  const cssContainer = classnames(tailwind, styles.container);
  const cssHeading = classnames('text-turmeric', styles.heading);

  // Animation
  // useAnimation(refEntryPoint);

  function decideOutput() {
    const banners = [
      `<h1 class="${cssHeading}">for people who<br />can't handle s*phora</h1>`, // v=0
      `<h1 class="${cssHeading}">Build your free <br />personalized routine</h1>`, // v=1
      `<h1 class="${cssHeading}">Build your custom <br />clean skincare routine</h1>`, // v=2
      `<h1 class="${cssHeading}">Build the perfect AM/PM <br />skincare routine for <u>you</u></h1>`, // v=3
      `<h1 class="${cssHeading}">Get personalized <br />skincare advice from real people</h1>` // v=4
    ];

    const { v: version } = router.query;
    const isString = typeof version === 'string';
    const value = isString ? parseInt(version, 10) : 0;
    const banner = banners[value] || banners[0];

    return { __html: banner };
  }

  return (
    <div className={cssComponent}>
      <div className={cssContainer} ref={refEntryPoint}>
        <div className={styles.left}>
          <HaldiLogo colors={colors} />
        </div>
        <div className={styles.right}>
          <div dangerouslySetInnerHTML={decideOutput()} />
          <HaldiButton
            bordersX="rust"
            className={styles.cta}
            href={SITE_SURVEY_PATH}
            thick={true}
          >
            {SITE_SURVEY_CTA}
          </HaldiButton>
        </div>
      </div>
    </div>
  );
};
