import * as React from 'react';
import { color } from '@haldi/style-guide';
import classnames from 'classnames';

// import { useAnimation } from './useAnimation';
import { HaldiStickyNote } from '@src/routes/common/components/HaldiStickyNote';

import styles from './styles.module.css';

export interface HomepageStickiesProps {
  className?: string;
}

/**
 * @name HomepageStickies
 * @description Two sticky notes on the homepage, stacked on top of each other.
 */
export const HomepageStickies = (_props: HomepageStickiesProps) => {
  // Styles
  const cssStickyOne = classnames(styles.sticky, styles.stickyOne);
  const cssStickyTwo = classnames(styles.sticky, styles.stickyTwo);

  // Animation
  // useAnimation(`.${styles.sticky}`)

  return (
    <div className="ui-container-xl relative">
      <HaldiStickyNote className={cssStickyOne} fill={color.pine} size="medium">
        <div
          className="p-1x py-2x text-24 md:p-5x md:text-32"
          style={{ transform: 'rotate(-3deg)' }}
        >
          your moisturizer
          <br />
          is actually great
        </div>
      </HaldiStickyNote>
      <HaldiStickyNote className={cssStickyTwo} rotate={1} size="small">
        <div className="p-1x text-24 md:p-2x md:text-32">
          but lets talk about
          <br />
          an exfoliator -megan
        </div>
      </HaldiStickyNote>
    </div>
  );
};
