export interface Data {
  copy: string;
  title: string;
}

export const data: Data[] = [
  {
    copy: 'We’ll start with your bathroom shelf, looking for Ingredient Offenders, the “trans -fats of skincare”, ingredients no one needs to be using.',
    title: 'Toss the <span>offender</span>'
  },
  {
    copy: 'We never want you to toss everything and start from scratch. We build around what you’re already using and loving.',
    title: 'Keep using <span>your faves</span>'
  },
  {
    copy: 'Your skin coach recommends products for the gaps in your routine. Free shipping, no minimum order and no subscriptions.',
    title: 'Shop your <span>Haldi picks</span>'
  },
  {
    copy: 'We’ll build your personal day and night routine, including products you’re already using. All you have to do is follow.',
    title: 'Your step-by-step <span>routine</span>'
  }
];
