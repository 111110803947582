import * as React from 'react';
import { useQuery } from '@apollo/client';
import classnames from 'classnames';
import Head from 'next/head';

import { SITE_TITLE } from '@src/config/constants';
import {
  GetOkDocument,
  GetOkQuery,
  GetOkQueryVariables
} from '@src/generated/hooks';
import { HomepageBrands } from '@src/routes/home/components/HomepageBrands';
import { HomepageExperience } from '@src/routes/home/components/HomepageExperience';
import { HomepageGetStarted } from '@src/routes/home/components/HomepageGetStarted';
import { HomepageIntro } from '@src/routes/home/components/HomepageIntro';
import { HomepageOverview } from '@src/routes/home/components/HomepageOverview';
import { HomepageRecommendations } from '@src/routes/home/components/HomepageRecommendations';
import { HomepageStickies } from '@src/routes/home/components/HomepageStickies';
import { HomepageStories } from '@src/routes/home/components/HomepageStories';
import { HomepageWeAre } from '@src/routes/home/components/HomepageWeAre';

import styles from './index.module.css';

export interface IndexProps {}

/**
 * @name Index
 * @description Application landing page (homepage)
 */
export const Index = (_props: IndexProps) => {
  // Hooks
  useQuery<GetOkQuery, GetOkQueryVariables>(GetOkDocument);

  // Styles
  const cssSection = classnames('ui-border-top');

  return (
    <>
      <Head>
        <title>Welcome | {SITE_TITLE}</title>
      </Head>

      <main className={styles.component}>
        <HomepageIntro />
        <HomepageStickies />
        <HomepageOverview className={cssSection} />
        <HomepageExperience className={cssSection} />
        <HomepageStories className={cssSection} />
        <HomepageBrands />
        <HomepageRecommendations className={cssSection} />
        <HomepageGetStarted className={cssSection} />
        <HomepageWeAre />
      </main>
    </>
  );
};

export default Index;
