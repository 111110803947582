export interface Data {
  image: string;
  title: string;
  width: number;
}

export const data: Data[] = [
  {
    image: '/images/brands/josh-rosebrook.png',
    title: 'Josh Rosebrook',
    width: 200
  },
  {
    image: '/images/brands/tata-harper.png',
    title: 'Tata Harper',
    width: 120
  },
  {
    image: '/images/brands/youth-to-the-people.png',
    title: 'Youth To The People',
    width: 300
  },
  {
    image: '/images/brands/pai.png',
    title: 'Pai',
    width: 100
  },
  {
    image: '/images/brands/indie-lee.png',
    title: 'Indie Lee',
    width: 200
  },
  {
    image: '/images/brands/alpha-h.png',
    title: 'Alpha H',
    width: 70
  },
  {
    image: '/images/brands/ursa-major.png',
    title: 'Ursa Major',
    width: 270
  },
  {
    image: '/images/brands/elta-md.png',
    title: 'Elta MD',
    width: 150
  }
];
