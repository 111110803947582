import * as React from 'react';
import classnames from 'classnames';

// import { useAnimation } from './useAnimation';
import styles from './styles.module.css';

export interface HomepageOverviewProps {
  className?: string;
}

/**
 * @name HomepageOverview
 * @description A high level overview of what Haldi is, and how it works.
 */
export const HomepageOverview = (props: HomepageOverviewProps) => {
  const { className } = props;

  // Hooks
  const refEntryPoint = React.useRef<HTMLDivElement>(null);
  const refCopy = React.useRef<HTMLParagraphElement>(null);

  // Styles
  const cssComponent = classnames(styles.component, className);

  // Animation
  // useAnimation(refEntryPoint, refCopy);

  return (
    <div className={cssComponent} ref={refEntryPoint}>
      <div className="ui-container-xl">
        <h2 className={styles.heading}>
          We sift through hundreds of skincare products to find the right
          routine for you
        </h2>

        <p className={styles.subhead} ref={refCopy}>
          It takes us about 2 days.{' '}
          <span className="text-pine">It's free.</span>
        </p>
      </div>
    </div>
  );
};
