import * as React from 'react';
import classnames from 'classnames';

import { Data, data } from './data';

import styles from './index.module.css';

export interface HomepageBrandsProps {
  className?: string;
}

/**
 * @name HomepageBrands
 * @description Responsive layout of some brands we carry and just so
 * happen to really like using.
 */
export const HomepageBrands = (props: HomepageBrandsProps) => {
  const { className } = props;

  // Styles
  const cssComponent = classnames(styles.component, className);

  // Markup
  const renderData = (entry: Data, index: number) => {
    const { image, title, width } = entry;

    return (
      <img
        alt={title}
        className={styles.brand}
        key={index}
        src={image}
        width={width}
      />
    );
  };

  return (
    <>
      <div className={cssComponent}>
        <div className="ui-container-xl">
          <h2 className={styles.heading}>
            A few brands <span>we love</span>
          </h2>
        </div>
      </div>
      <div className={styles.content}>{data.map(renderData)}</div>
    </>
  );
};
